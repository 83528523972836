body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #e9ecef;
    color: #343a40;
}

.profile-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.sidenav {
    width: 270px;
    background-color: #343a40;
    color: white;
    padding: 20px;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidenav .profile img {
    border-radius: 50%;
    margin-bottom: 15px;
    border: 3px solid #007bff;
}

.sidenav .name {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
}

.sidenav .job {
    font-size: 14px;
    color: #ccc;
    margin-bottom: 20px;
}

.sidenav-url {
    width: 100%;
    text-align: center;
}

.sidenav-url .url {
    margin-bottom: 15px;
}

.sidenav-url .url a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 10px;
    background-color: #007bff;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.sidenav-url .url a:hover {
    background-color: #0056b3;
}

.main {
    margin-left: 290px;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
}

.card {
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 900px;
}

.card-body table {
    width: 100%;
    border-collapse: collapse;
}

.card-body table tr td {
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    font-size: 16px;
}

.card-body table tr td:nth-child(2) {
    width: 15px;
    text-align: center;
}

.card-body input[type="number"],
.card-body input[type="url"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.save-button {
    background-color: #28a745;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.save-button:hover {
    background-color: #218838;
}

@media (max-width: 768px) {
    .sidenav {
        width: 100%;
        height: auto;
        position: relative;
        flex-direction: row;
        justify-content: space-around;
        padding: 10px;
    }

    .main {
        margin-left: 0;
        padding: 10px;
    }

    .card {
        padding: 20px;
    }

    .card-body table tr td {
        font-size: 14px;
        padding: 10px;
    }

    .card-body input[type="number"],
    .card-body input[type="url"] {
        font-size: 14px;
    }
}