@import url(https://fonts.googleapis.com/css?family=Quicksand);

/* #root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
} */

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

/* General Calendar Styles */
.rbc-calendar {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  color: #333;
}

.rbc-calendar {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.rbc-header {
  background-color: #5e72e4;
  /* Header background */
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.rbc-header+.rbc-header {
  border-left: 1px solid #ddd;
}

.rbc-month-view .rbc-day-bg:hover {
  background-color: #e9f7ff;
  /* Hover effect for days */
}

/* Styling Today */
.rbc-today {
  background-color: #ffe5b4;
  /* Highlight for today */
  border: 1px solid #ffa500;
}

/* Event Styles */
.rbc-event {
  background-color: #5e72e4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 14px;
  text-align: left;
}

.rbc-event:hover {
  background-color: #4a5bc7;
  /* Hover effect */
}

/* Agenda View Styles */
.rbc-agenda-view {
  background-color: #fdfdfd;
}

.rbc-agenda-view .rbc-agenda-date-cell {
  font-weight: bold;
  color: #333;
}

/* Time Grid Styles */
.rbc-time-view {
  border-top: 1px solid #ddd;
}

.rbc-time-view .rbc-time-content {
  background: #fdfdfd;
}

.rbc-time-view .rbc-time-gutter {
  background: #f5f5f5;
  border-right: 1px solid #ddd;
}

/* Buttons in the Toolbar */
.rbc-toolbar {
  padding: .5rem 10px;
}

.rbc-toolbar button {
  background-color: #5e72e4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
}

.rbc-toolbar button:hover {
  background-color: #4a5bc7;
}

.rbc-toolbar button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.rbc-toolbar-label {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0 10px;
}

/* Custom Scrollbars */
.rbc-time-content {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f9f9f9;
}

.rbc-time-content::-webkit-scrollbar {
  width: 8px;
}

.rbc-time-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.rbc-time-content::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Custom Centered Modal */
.custom-centered-modal .modal-dialog {
  position: absolute;
  /* Ensure absolute positioning */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust for true center alignment */
  max-width: 400px;
  /* Set a smaller modal width */
  width: 90%;
  /* Ensure responsiveness */
  z-index: 1050;
  /* Ensure it appears above other elements */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.custom-centered-modal .modal-content {
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  background-color: #ebf2ed;
  /* Ensure absolute positioning */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust for true center alignment */
  max-width: 400px;
  /* Set a smaller modal width */
  width: 90%;
  /* Ensure responsiveness */
  z-index: 1050;
  /* Ensure it appears above other elements */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.custom-centered-modal .modal-header {
  background-color: #007bff;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 15px;
}

.custom-centered-modal .modal-body {
  padding: 20px;
  font-family: 'Quicksand', sans-serif;
}

.modal-body .form-group {
  display: flex;
  justify-content: space-between;
}

.custom-centered-modal .modal-footer {
  display: flex;
  justify-content: space-between;
}

.rbc-toolbar span:nth-child(3) {
  visibility: hidden;
}