.shared-company-list-container {
  background-color: var(--app-container);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 80%;
  color: var(--main-color);
  overflow: hidden;
  margin-bottom: 24px;
}

.shared-company-list-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: var(--main-color);
}

.shared-company-list-table-container {
  overflow-y: auto;
  max-height: calc(100% - 60px);
}

.shared-company-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.shared-company-table th,
.shared-company-table td {
  padding: 12px 10px;
}

.shared-company-table th {
  background-color: var(--link-color-active-bg);
  color: var(--link-color-active);
  font-size: 16px;
  font-weight: bold;
}

.shared-company-table tbody tr {
  background-color: var(--message-box-hover);
  transition: background-color 0.3s ease;
}

.shared-company-table tbody tr:hover {
  background-color: var(--link-color-hover);
}

.shared-company-table td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.shared-company-table td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.shared-company-table th.col-1,
.shared-company-table td.col-1 {
  width: 14.28%;
}

.shared-company-table th.col-2,
.shared-company-table td.col-2 {
  width: 28.57%;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

.dark .shared-company-list-container {
  background-color: var(--app-container);
  color: var(--main-color);
}

.dark .shared-company-table th {
  background-color: var(--projects-section);
  color: var(--main-color);
}

.dark .shared-company-table tbody tr {
  background-color: var(--message-box-hover);
}

.dark .shared-company-table tbody tr:hover {
  background-color: var(--link-color-hover);
}

@media screen and (max-width: 768px) {
  .shared-company-list-container {
    width: 95%;
    padding: 15px;
  }

  .shared-company-table th,
  .shared-company-table td {
    padding: 10px 8px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .shared-company-list-container {
    width: 100%;
    margin: 20px auto;
  }

  .shared-company-table th,
  .shared-company-table td {
    padding: 8px 6px;
    font-size: 12px;
  }
}