/* HrModal.css */

.hrlist-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.hrlist-modal-content {
  background: var(--projects-section);
  width: 90%;
  max-width: 1200px;
  height: 90%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  color: var(--main-color);
  padding: 25px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.hrlist-modal-header {
  text-align: center;
  padding: 15px 0;
  border-bottom: 2px solid var(--message-box-border);
  margin-bottom: 20px;
}

.hrlist-modal-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 25px;
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
}

/* Minimalistic scrollbar styles */
.hrlist-modal-body::-webkit-scrollbar {
  width: 8px;
}

.hrlist-modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.hrlist-modal-body::-webkit-scrollbar-thumb {
  background-color: rgba(var(--main-color-rgb), 0.3);
  border-radius: 4px;
}

.hrlist-modal-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(var(--main-color-rgb), 0.5);
}

.grid-item {
  background: var(--app-container);
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.grid-item:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.hrlist-modal-overlay .grid-item-0-0 {
  grid-area: 1 / 1 / 2 / 2;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
}

.hrlist-modal-overlay .grid-item-0-1 {
  grid-area: 1 / 2 / 2 / 3;
  height: auto;
  overflow-y: auto;
}

.hrlist-modal-overlay .grid-item-1-0 {
  grid-area: 2 / 1 / 3 / 2;
  height: 400px;
  overflow-y: auto;
}

.hrlist-modal-overlay .grid-item-1-1 {
  grid-area: 2 / 2 / 3 / 3;
  height: auto;
  overflow: hidden;
}

.hrlist-modal-overlay h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 24px;
  margin-bottom: 20px;
}

.hrlist-modal-overlay p {
  margin: 12px 0;
  font-size: 16px;
  line-height: 1.5;
}

.hrlist-modal-overlay label {
  display: block;
  margin-top: 15px;
  color: var(--secondary-color);
  font-weight: bold;
}

.hrlist-modal-overlay input,
.hrlist-modal-overlay textarea,
.hrlist-modal-overlay select,
.hrlist-modal-overlay .react-datepicker-wrapper {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border: 1px solid var(--message-box-border);
  border-radius: 6px;
  background: var(--message-box-hover);
  color: var(--main-color);
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.hrlist-modal-overlay input:focus,
.hrlist-modal-overlay textarea:focus,
.hrlist-modal-overlay select:focus {
  outline: none;
  border-color: var(--link-color-active);
}

.hrlist-modal-overlay button {
  background-color: var(--button-bg);
  color: var(--link-color-active);
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.hrlist-modal-overlay button:hover {
  background-color: var(--link-color-hover);
}

.hrlist-modal-overlay table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 15px;
}

.hrlist-modal-overlay th,
.hrlist-modal-overlay td {
  border: 1px solid var(--message-box-border);
  padding: 12px;
  text-align: left;
}

.hrlist-modal-overlay th {
  background-color: var(--link-color-active-bg);
  color: var(--link-color-active);
  font-weight: bold;
}

.hrlist-modal-overlay td {
  background-color: var(--message-box-hover);
}

.hrlist-modal-overlay tr:nth-child(even) td {
  background-color: rgba(var(--message-box-hover-rgb), 0.7);
}

.hrlist-modal-overlay .grid-item-0-0 > * {
  margin-bottom: 20px;
}

.hrlist-modal-overlay .grid-item-0-0 > *:last-child {
  margin-bottom: 0;
}

.hrlist-modal-overlay .grid-item-0-0 .info-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.hrlist-modal-overlay .grid-item-0-0 .info-row label {
  margin-top: 0;
  margin-right: 10px;
  min-width: 150px;
}

.hrlist-modal-overlay .grid-item-0-0 .info-row .data {
  flex-grow: 1;
}

.hrlist-modal-overlay .grid-item-0-0 .react-datepicker-wrapper {
  width: auto;
}
