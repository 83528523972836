/* HRContactForm.css */
.hr-contactinfo-form {
  justify-content: space-between;
}
.form-container {
  background-color: var(--app-container);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 80%; /* Make the form wider */
  margin: 40px auto;
  color: var(--main-color);
  overflow: hidden;
}

.form-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: var(--main-color);
}

/* .form-container form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
} */

/* .form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
} */

/* .form-container label {
  font-size: 16px;
  color: var(--main-color);
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */

/* .form-container input {
  padding: 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px;
  color: var(--main-color);
  background-color: var(--search-area-bg);
  width: 100%;
  box-sizing: border-box;
} */

/* .form-row:last-child {
  margin-bottom: 0; 
} */

/* .form-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--button-bg);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: block;
} */

/* .form-container button:hover {
  background-color: var(--link-color-hover);
} */


.dark .form-container {
  background-color: var(--app-container);
  color: var(--main-color);
}

.dark .form-container input {
  border-color: var(--message-box-border);
  background-color: var(--search-area-bg);
  color: var(--main-color);
}

.dark .form-container input:hover {
  outline: auto;
}



.dark .form-container button {
  background-color: var(--button-bg);
}

.dark .form-container button:hover {
  background-color: var(--link-color-hover);
}
