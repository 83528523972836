/* ShareCompanyContact.css */
/* .company-contact-form {
  
  row-gap: 10px; 
  column-gap: 20px; 
  overflow-y: auto; 
  max-height: 100%; 
} */
.company-form-container {
  background-color: var(--app-container);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 80%; /* Make the form wider */
  margin: 40px auto;
  color: var(--main-color);
  overflow: hidden;
}

/* .form-container {
  background-color: var(--app-container);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 40px auto;
  color: var(--main-color);

} */

/* .company-form-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: var(--main-color);
} */

/* .grid-item {
  display: flex;
  flex-direction: column;
} */

/* .checkbox-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-column: span 2; 
} */

/* .checkbox-group span {
  margin-right: 10px; 
} */

/* .company-form-container label {
  font-size: 16px;
  color: var(--main-color);
} */

/* .company-form-container input,
.company-form-container select {
  padding: 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px;
  color: var(--main-color);
  background-color: var(--search-area-bg);
  width: 100%;
  box-sizing: border-box;
} */

/* .grid-submit {
  grid-column: span 3; 
} */

/* .company-form-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--button-bg);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: block;
} */

/* .company-form-container button:hover {
  background-color: var(--link-color-hover);
} */

@media screen and (max-width:640px){
  .checkbox-btn{
    margin-left: 4px;
  }
}


.dark .company-form-container {
  background-color: var(--app-container);
  color: var(--main-color);
}

.dark .company-form-container input,
.dark .company-form-container select {
  border-color: var(--message-box-border);
  background-color: var(--search-area-bg);
  color: var(--main-color);
}

.dark .company-form-container button {
  background-color: var(--button-bg);
}

.dark .company-form-container button:hover {
  background-color: var(--link-color-hover);
}

