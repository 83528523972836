@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Rules */
* {
  @apply box-border;
}
html,
body {
  @apply h-full;
  background-color: #f3f6fd;
  font-family: "Open Sans", sans-serif;
}
#root {
  @apply h-full flex flex-col;
}
a {
  @apply no-underline;
}
ul {
  @apply list-none p-0 m-0 font-sans text-black;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}