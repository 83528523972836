.cont_principal {
    overflow: hidden;
    position: relative;
  }
  
  .cont_error {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .cont_error_active h1 {
    transform: translateX(0%);
  }
  
  .cont_error_active p {
    transform: translateX(0%);
  }
  
  .cont_aura_1 {
    width: 300px;
    height: 120%;
    top: 25px;
    right: -340px;
    transition: all 0.5s;
  }
  
  .cont_aura_2 {
    width: 100%;
    height: 300px;
    right: -10%;
    bottom: -301px;
    transition: all 0.5s;
    z-index: 5;
  }
  
  .cont_error_active .cont_aura_1 {
    transform: rotate(20deg);
    right: -170px;
    animation: animation_error_1 4s linear infinite alternate;
  }
  
  .cont_error_active .cont_aura_2 {
    transform: rotate(-20deg);
    animation: animation_error_2 4s linear infinite alternate;
  }
  
  @keyframes animation_error_1 {
    from {
      transform: rotate(20deg);
    }
    to {
      transform: rotate(25deg);
    }
  }
  
  @keyframes animation_error_2 {
    from {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(-20deg);
    }
  }
  