/* FirstLogin Page */

.first-login-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
}

.first-login-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1000px;
}

.first-login-profile-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.first-login-photo-upload {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f0f0f0;
  overflow: hidden;
  margin-right: 20px;
}

.first-login-photo-upload input[type="file"] {
  display: none;
}

.first-login-photo-upload label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #5d5d5d;
  font-size: 24px;
}

.first-login-photo-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.first-login-user-name {
  font-size: 24px;
  color: #5d5d5d;
  display: flex;
  align-items: center;
}

.first-login-container h2 {
  color: #5d5d5d;
  margin-bottom: 20px;
  text-align: center;
}

.first-login-form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0;
  gap: 20px;
}

.first-login-input-group {
  display: flex;
  align-items: center;
  width: 48%;
  position: relative;
}

.first-login-input-group i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

.first-login-input-group input,
.first-login-input-group select {
  width: 100%;
  padding: 10px 10px 10px 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.first-login-input-group input::placeholder {
  color: #3b3a3a;
}

.first-login-input-group input[type="file"] {
  padding: 0;
  display: none;
}

.first-login-input-group.file-input label {
  width: 100%;
  padding: 10px 10px 10px 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #3b3a3a;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first-login-input-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  position: relative;
}

.first-login-input-group select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  width: 0;
  height: 0;
  pointer-events: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #aaa;
  transform: translateY(-50%);
}

.first-login-input-group.required::after {
  content: " *";
  color: red;
  position: absolute;
  right: 10px;
  font-size: 16px;
}

.first-login-container button {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: none;
  border-radius: 5px;
  background-color: #6c63ff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.first-login-container button:hover {
  background-color: #5752d8;
}
